import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetOrdiniToInit, setOrdiniMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteOrdini } from "services/ordiniService";
import moment from "moment";
import ReactPaginate from "react-paginate";
import LoadingSpinner from "components/LoadingSpinner";
import { getPacchettiOrdine } from "services/pagamentoService";
import DetailOrdine from "template/DetailOrdine";
type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const OrdiniTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const rData = useSelector((state: RootState) => state.ordini);
    const [isLoading, setIsLoading] = useState(false);

    const elencoImprese: any [] = []
    const [lstElencoImprese, setListElencoImprese] = useState(elencoImprese);
    const [postsPerPage] = useState(10);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [countImprese, setcountImprese] = useState(elencoImprese.length);
    const [listaPacchettiOrdine, setListPacchettiOrdine] = useState(undefined);
    
    const [showDetailOrdine, setShowDetailOrdine] = useState(false);
    
    const [detailOrdineData, setDetailOrdineData] = useState(undefined);

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };


    const handleSearchButton = () => {

        filterBySearch(search)
    
    }

    const getPacchettoOrdineUtene = (idordine) =>{
        setIsLoading(true)
        getPacchettiOrdine(idordine).then((response) =>{
            console.log(response)
            setListPacchettiOrdine(response.data)
            let dati = {
                id: idordine,
                lista: response.data,
            }
            setDetailOrdineData(dati)
            setShowDetailOrdine(true)
            setIsLoading(false)
        })
    }

    const handleDettaglioOrdine  = (idordine) => {
        getPacchettoOrdineUtene(idordine)
    }

    const getAllPosts = async (elenco_input) => {
        let res = rData.list//rDataElenco
        if(elenco_input != null && elenco_input.length > 0){
            res = elenco_input
        }
        const data = res
        let slice = null;
        if(res.length < 11){
             //res.list;
            slice = data
        }else{

            if(offset > 1){
                slice = data.slice((offset - 1) * 10 , (((offset - 1) * 10) + postsPerPage) -1)
            }else{
                slice = data.slice(offset - 1 , offset - 1 + postsPerPage)
            }
            
        }

        
        // For displaying Data
        const postData = getPostData(slice)
      
        // Using Hooks to set value
        setAllPosts(postData)
        setPageCount(Math.ceil(data.length / postsPerPage))

        setcountImprese(res.length)
    }

    const getPostData = (data) => {
        return (
          data.map(row => 
            <Row className="shadow box-ricerca-elenco row  margin-row">
                <Col md={2} xs={12} xl={2} className="center-block-custom align-center cursor-pointer">
                {handleIcon(row)}
                {statoOrdine(row)}
                </Col>
                
                <Col md={8} xs={12} xl={8}>
                    <Row>
                        <div className="col-sm-6">Num Ordine: <p><strong>{valoreDato(row.id)}</strong></p></div>
                        <div className="col-sm-6">Data: <p><strong>{moment(row.data).format('DD/MM/YYYY HH:mm:ss')}</strong></p></div>
                        <div className="col-sm-6">Importo: <p><strong>€ {Number(row.prezzo_totale).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })}</strong></p></div>
                        <div className="col-sm-6">Metodo Pagamento: <p><strong>{valoreDato(row.metodo_di_pagamento)}</strong></p></div>
                    </Row>
                </Col>
                <Col md={2} xs={12} xl={2} className="center-block-custom align-center cursor-pointer" onClick={() => handleDettaglioOrdine(row.id)}>
                    <i className="fa fa-file color_cel icon-size-big"></i>
                    <p className="font-size-12 color_cel">Dettaglio Ordine</p>
                </Col>
            </Row>
            )
        )
      
      }

      const valoreDato = (dato) => {
        if(dato == null || dato === ""){
            return "Dato non fornito"
        }else{
            return dato
        }
    }

    const valorestatoDato = (dato) => {
        if(dato === "0"){
            return "pending"
        }else if(dato === "3"){
            return "completato"
        }else if(dato === "2"){
            return "rifiutato"
        }else if(dato === "5"){
            return "annullato"
        }else{
            return "dato non fornito"
        }
    }

    const valorePagamento = (dato) => {
        if(dato === "nexi"){
            return "carta di credito"
        }else if(dato === "paypal"){
            return "paypal"
        }else{
            return "dato non fornito"
        }
    }

      const filterBySearch = (event) => {
        const query = event
        let query_stato = query
        var updatedList = lstElencoImprese
        updatedList = updatedList.filter((item) => {
            if(
            (item.id != null && item.id.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
            (item.stato_ordine != null && valorestatoDato(item.stato_ordine).toLowerCase().indexOf(query.toLowerCase()) !== -1) || 
            (item.data != null && moment(item.data).format('DD/MM/YYYY HH:mm:ss').toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
            (item.metodo_di_pagamento != null && valorePagamento(item.metodo_di_pagamento).toLowerCase().indexOf(query.toLowerCase()) !== -1) || 
            (item.prezzo_totale != null && item.prezzo_totale.toLowerCase().indexOf(query.toLowerCase()) !== -1)
            ){
                return item
            }
        });
        // Trigger render with updated values
        setListElencoImprese(updatedList)
        getAllPosts(updatedList)
    };
    

    const handleIcon = (row) => {

        if(row.stato_ordine === '3'){
            return (<i className="fa fa-check-circle color-icon-green icon-size-big"></i>)
        }else if(row.stato_ordine === '0'){
            return (<i className="fa fa-hourglass-end icon-size-normal icon-size-big"></i>)
        }else if(row.stato_ordine === '2'){
                return (<i className="fa fa-exclamation-circle color-icon-red icon-size-big"></i>)
        }else if(row.stato_ordine === '5'){
                return (<i className="fa fa-exclamation-circle color-icon-orange icon-size-big"></i>)
        }

    }

    const statoOrdine = (row) => {

        if(row.stato_ordine === '3'){
            return (<p className="font-size-12 color-icon-green">Completato</p>)
        }else if(row.stato_ordine === '0'){
            return (<p className="font-size-12 color-icon-gray">Pending</p>)
        }else if(row.stato_ordine === '2'){
                return (<p className="font-size-12 color-icon-red">Rifiutato</p>)
        }else if(row.stato_ordine === '5'){
                return (<p className="font-size-12 color-icon-orange">Annullato</p>)
        }

    }





    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    // useEffect(() => {
    //     if (rData && rData.list && rData.list.length === 0) {
    //         dispatch(resetOrdiniToInit());
    //         getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
    //     }
    // },[rData.list.length])

    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
             dispatch(resetOrdiniToInit());
             getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
             getAllPosts(rData.list);
        }else{
            getAllPosts(rData.list);
            setListElencoImprese(rData.list)
        }
    },[rData, rData.list.length, offset])

    const handleReset = () => {
        setSearch('');
        dispatch(resetOrdiniToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteOrdini(rowData.id);
            if (response) {
                dispatch(resetOrdiniToInit());
                dispatch(setOrdiniMessage("Deleted Successfully"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setOrdiniMessage("Some error occured!"));
            }
        }
    }

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setOrdiniMessage(''));
        hideShowForm('add');
    }

    
    return (
        <>
         <div className="d-flex flex-column" >
            <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                    <div className="col-sm-9">
                        <h4>I Miei Ordini</h4>
                    </div>
                    <div className="col-sm-3 text-align-right">
                        
                        <>
                        <Button variant="info" className="" onClick={() => handleReset()}><i className="fa fa-refresh"></i> Aggiorna ({countImprese})</Button>
                        
                        </>
                                        
                    </div>
            </Row>
        </div>
        <div>
            <Col className="ml-auto padding-bottom20" md={4} xs={12} xl={4}>
                    <InputGroup>
                        <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button  variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearchButton}>
                            Search
                        </Button>
                    </InputGroup>
                </Col>
        </div>
        <div className="d-flex flex-column" >
                
        {isLoading ? <LoadingSpinner /> : isLoading}

                
        <div className="main-app">

        <ReactPaginate
            previousLabel={"pre"}
            nextLabel={"succ"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            //subContainerClassName={"pages pagination"}
            activeClassName={"active"} />
            {/* Display all the posts */}
            {posts}
            {/* Using React Paginate */}
        </div>


        <ConfirmationModal buttonNegative="Cancel" buttonPositive="Delete" title="Delete Confirmation" show={showDelete} body={"Are you sure?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
        <DetailOrdine
          buttonNegative="Chiudi" 
          title="Dettaglio Ordine" 
          show={showDetailOrdine} 
          body={detailOrdineData} 
          onNegative={() => setShowDetailOrdine(false)}
      />
        </div>
 

                


        </>
        // <Card className="shadow mb-4">
        //     <Card.Header className="py-3">
        //         <h6 className="m-0 font-weight-bold text-primary">Ordini List ({rData.totalCount})
        //             <Button variant="light" className="btn-circle btn-sm ml-2" onClick={handleReset}><i className="fa fa-refresh"></i></Button>
        //             <Button className="btn-icon-split float-right" onClick={handleAddButtonClick}>
        //                 <span className="icon text-white-50">
        //                     <i className="fas fa-add"></i>
        //                 </span>
        //                 <span className="text">Add New</span>
        //             </Button></h6>

        //     </Card.Header>
        //     <Card.Body>
        //         <Col className="ml-auto" md={3} xs={12} xl={3}>
        //             <InputGroup>
        //                 <Form.Control
        //                     placeholder="Search"
        //                     aria-label="Search"
        //                     aria-describedby="basic-search"
        //                     onChange={(e) => setSearch(e.target.value)}
        //                 />
        //                 <Button disabled={search.length <= 2} variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearch}>
        //                     Search
        //                 </Button>
        //             </InputGroup>
        //         </Col>
        //         <div className="table-responsive">
        //             <DataTable
        //                 selectableRows={true}
        //                 onSelectedRowsChange={handleRowSelection}
        //                 paginationPerPage={Constant.defaultPageNumber}
        //                 paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
        //                 columns={columns} data={rData.list}
        //                 onChangeRowsPerPage={handlePerRowsChange}
        //                 paginationTotalRows={rData.totalCount}
        //                 className="table table-bordered"
        //                 pagination
        //                 paginationServer
        //                 onChangePage={handlePageChange}></DataTable>
        //         </div>
        //     </Card.Body>
        //     <ConfirmationModal buttonNegative="Cancel" buttonPositive="Delete" title="Delete Confirmation" show={showDelete} body={"Are you sure?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
        // </Card>
    );
}

