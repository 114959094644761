import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppDispatch } from "redux/store";
import { tokenAPICALL } from "services/authService";
import { setJWTToken } from "redux/actions";
import { setError } from "redux/slices/auth";
import { Alert, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
//import { getLoginByUsername } from "services/ws_loginService";
import { passwordAPICALL, passwordRecuperaAPICALL } from "services/passwordService";
import { Link } from "react-router-dom";

const RecuperaPassword: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const rData = useSelector((state: RootState) => state.authToken);
    
    const[passwordNoUguali, setPasswordNoUguali] = useState(null);
    const[passwordModificata, setPasswordModificata] = useState(null);


    const searchParam = window.location.search;
    const params = new URLSearchParams(searchParam);
    const accessToken = params.get('service-access')
    
    const formik = useFormik({
        initialValues: {
            confirmpassword: '',
            password: ''
        },
        onSubmit: async (values) => {
            //setInvioEmail(true)
            if(values.password != values.confirmpassword){
                setPasswordNoUguali(false)
            }else{
                passwordRecuperaAPICALL(values.password, accessToken).then((response) => {
                    if(response != null && response.data != null && response.data.document){
                        setPasswordModificata(true)
                    }else{
                        setPasswordModificata(false)
                    }
                })
            }            
        },
    });

    const handlePageLogin = () => {

        navigate('/', { replace: true });

    }

    useEffect(() => {
        setPasswordNoUguali(true)
        setPasswordModificata(null)
        document.body.classList.toggle('bg-gradient-primary', true);
    }, [])
    return (

        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-12">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Recupera Password</h1>
                                        </div>
                                        {passwordModificata != null && !passwordModificata &&
                                            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">Attenzione la modifica non è avvenuta riprovare o contattare assistenza!
                                                <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setError(''))}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </Alert>
                                        }
                                        {passwordModificata &&
                                            <Alert variant={Constant.defaultAlertSuccess} className="alert-dismissible fade">Modifica password avvenuto con successo, accedi nuovamente con i nuovi dati!
                                                <Link className="close link-accedi" to={"/"}>
                                                    Accedi
                                                </Link>
                                            </Alert>
                                        }
                                        <Form className="user" onSubmit={formik.handleSubmit}>
                                            <Form.Group>
                                                <label className="form-control-label">Nuova Password</label>
                                                <Form.Control type="password" name="password" className="form-control-user" value={formik.values.password}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!formik.touched.password && !!formik.errors.password}
                                                    isValid={!!formik.touched.password && !formik.errors.password}
                                                ></Form.Control>
                                                {formik.errors.password && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.password}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            <Form.Group>
                                                <label className="form-control-label">Conferma Password</label>
                                                <Form.Control type="password" name="confirmpassword" className="form-control-user" value={formik.values.confirmpassword}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!formik.touched.confirmpassword && !!formik.errors.confirmpassword}
                                                    isValid={!!formik.touched.confirmpassword && !formik.errors.confirmpassword}
                                                ></Form.Control>
                                                {formik.errors.confirmpassword && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.confirmpassword}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            <Button type="submit" className="btn-user btn-block btn btn-info btn btn-primary" variant="primary" >Invia</Button>
                                        </Form>
                                        <hr />
                                        {rData.errorMessage ?
                                            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.errorMessage}
                                                <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setError(''))}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </Alert> : null}
                                        {!passwordNoUguali &&
                                            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">Controlla i valori inseriti, le due password non coincidono!
                                                <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setError(''))}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </Alert>
                                        }
                                        
                                        <div className="copyright text-center my-auto">
                                            <span>Copyright &copy; elenco imprese 2022</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
};

export default RecuperaPassword;


