import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setAssistenzaMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { addAssistenza, inserisciAssistenza, updateAssistenza } from "services/assistenzaService";
import { parseJwtToken } from "services/tokenService";
import { getOneWs_Login } from "services/ws_loginService";
import moment from "moment";
import { sendMailAssistenza } from "services/mailService";
type Props = {
    row?: any,
    // hideShowForm: (actionName) => void;
    // getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const AssistenzaUtente: React.FC<Props> = () => {
    const dispatch = useAppDispatch();
    const iValue={id:'',id_utente:'',nome:'',cognome:'',azienda:'',email:'',telefono:'',descrizione:''};
    //let initialValues = iValue
   
    

    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);
    var idUtente = 0
    let iValueUser = {
        id: '',
        id_utente: '',
        nome: '',
        cognome: '',
        azienda: '',
        email: '',
        telefono: '',
        descrizione: ''
    }
    const iValueEmpty = {
        id: '',
        id_utente: '',
            nome: '',
            cognome: '',
            azienda: '',
            email: '',
            telefono: '',
            descrizione: ''
    }

    const[datiLoad, setDatiLoad] = useState(null);

    const[id_utente, setIdUtente] = useState('');
    const[nome, setNome] = useState('');
    const[cognome, setCognome] = useState('');
    const[azienda, setAzienda] = useState('');
    const[email, setEmail] = useState('');
    const[telefono, setTelefono] = useState('');
    const[descrizione, setDescrizione] = useState('');

    const[messaggio, setMessaggio] = useState("")
    const[esito, setEsito] = useState(false)

    


    useEffect(() => {
        console.log("jwtToken")
        console.log(jwtToken)
        console.log(jwtToken)
        console.log(jwtToken)
        if(jwtToken && jwtToken.data != null ){
            idUtente = jwtToken.data.id;
    
            if(datiLoad == null){
                getOneWs_Login(idUtente).then((response) => {
                    if (response && response.data && response.data.document) {
                      console.log(response);
                      const iValueUser_set = {
                        id: '',
                        id_utente: response.data.document.id,
                        nome: response.data.document.nome_referente,
                        cognome: response.data.document.cognome_referente,
                        azienda: response.data.document.nome_azienda,
                        email: response.data.document.email,
                        telefono: response.data.document.telefono,
                        descrizione: ''
                    }
        
                    setAzienda(response.data.document.nome_azienda)
                    setEmail(response.data.document.email)
                    setTelefono(response.data.document.telefono)
                    iValueUser = iValueUser_set;
                    setDatiLoad(iValueUser);
    
                    }else{
                        iValueUser = iValueEmpty
                        setDatiLoad(iValueUser);
                    }
                  })
            }
            
        }else{
            iValueUser = iValueEmpty
        }
      }, [datiLoad]);
    
    const formik = useFormik({
        initialValues: {
            id_utente: 0,
            nome: "",
            cognome: "",
            email: "",
            azienda:"",
            telefono:"",
            stato: 0,
            data_richiesta: moment(),
            descrizione:"",
        },
        onSubmit: async (values) => {

            if(jwtToken && jwtToken.data != null){
                values.id_utente =jwtToken.data.id
                values.nome = datiLoad.nome
                values.cognome = datiLoad.cognome
                values.email = datiLoad.email
                values.telefono = datiLoad.telefono
                values.azienda = datiLoad.azienda
            }
                const response = await inserisciAssistenza(values);
                if (response) {
                    dispatch(setAssistenzaMessage("Added Successfully"));
                    //getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    //hideShowForm('');
                    setMessaggio("Richiesta inserita con successo")
                    // let idrich = response.data.document
                    
                    // let dati = {
                    //     email: "tecnoinnovis@gmail.com",
                    //     messaggio : values.descrizione,
                    //     idrich: idrich


                    // }
                    // sendMailAssistenza(dati).then((result)=>{})
                    setEsito(true)

                } else {
                    dispatch(setAssistenzaMessage("Some error occured!"));
                    setMessaggio("Richiesta non inserita con successo")
                    setEsito(true)
                }
        },
        
        validationSchema: yup.object({
            //id_utente: yup.number().nullable(),
            nome: yup.lazy((value) => {
                return jwtToken.data == null ? yup.string().required('Campo Obbligatorio') : yup.string();
            }),
            cognome: yup.lazy((value) => {
                return jwtToken.data == null ? yup.string().required('Campo Obbligatorio') : yup.string();
            }),
            azienda: yup.lazy((value) => {
                return jwtToken.data == null ? yup.string().required('Campo Obbligatorio') : yup.string();
            }),
            email:yup.lazy((value) => {
                return jwtToken.data == null ? yup.string().required('Campo Obbligatorio') : yup.string();
            }),
            telefono:yup.lazy((value) => {
                return jwtToken.data == null ? yup.string().required('Campo Obbligatorio') : yup.string();
            }),
            descrizione: yup.string().required('Campo Obbligatorio'),
            //stato: yup.number().required('stato is required'),
        }),
    });

    

    useEffect(() => {
        document.body.classList.toggle('bg-gradient-primary', true);
    }, [])
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 d-none d-lg-block bg-login-image" style={{ height: 280, margin : 'auto'}}></div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Benvenuto!</h1>
                                        </div>
        <Card className="shadow mb-4">
            <Card.Body>
                {esito &&    
                <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{messaggio}
                    <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setEsito(false)}>
                    <span aria-hidden="true">&times;</span>
                    </Button>
                </Alert>
                }
            
                <Form onSubmit={formik.handleSubmit}>
                {(jwtToken == null || jwtToken.data == null) && (datiLoad == null) && 
                <>
                <Form.Group>
<label className="form -control-label no-padding">Nome</label>
<Form.Control type="text" name="nome" className="form-control" value={formik.values.nome}
onChange={formik.handleChange}
onBlur={formik.handleBlur}
isInvalid ={!!formik.touched.nome && !!formik.errors.nome}
isValid ={!!formik.touched.nome && !formik.errors.nome}
></Form.Control>
{
    formik.errors.nome && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nome}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label no-padding">cognome</label>
<Form.Control type="text" name="cognome" className="form-control" value={formik.values.cognome}
onChange={formik.handleChange}
onBlur={formik.handleBlur}
isInvalid ={!!formik.touched.cognome && !!formik.errors.cognome}
isValid ={!!formik.touched.cognome && !formik.errors.cognome}
></Form.Control>
{
    formik.errors.cognome && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.cognome}
    </Form.Control.Feedback>
)}
</Form.Group>
 <Form.Group>
<label className="form -control-label no-padding">azienda</label>
<Form.Control type="text" name="azienda" className="form-control" value={formik.values.azienda}
onChange={formik.handleChange}
//onBlur={formik.handleBlur}
isInvalid ={!!formik.touched.azienda && !!formik.errors.azienda}
isValid ={!!formik.touched.azienda && !formik.errors.azienda}
></Form.Control>
{
    formik.errors.azienda && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.azienda}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label no-padding">email</label>
<Form.Control type="text" name="email" className="form-control" value={formik.values.email}
onChange={formik.handleChange}
onBlur={formik.handleBlur}
isInvalid ={!!formik.touched.email && !!formik.errors.email}
isValid ={!!formik.touched.email && !formik.errors.email}
></Form.Control>
{
    formik.errors.email && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.email}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label no-padding">telefono</label>
<Form.Control type="text" name="telefono" className="form-control" value={formik.values.telefono}
onChange={formik.handleChange}
onBlur={formik.handleBlur}
isInvalid ={!!formik.touched.telefono && !!formik.errors.telefono}
isValid ={!!formik.touched.telefono && !formik.errors.telefono}
></Form.Control>
{
    formik.errors.telefono && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.telefono}
    </Form.Control.Feedback>
)}
</Form.Group>
                </>

} 
{jwtToken != null && jwtToken.data != null && jwtToken.data.id != null && datiLoad != null &&
<>
<p>Autenticato come: <h6><strong>{datiLoad.azienda}</strong></h6></p>

</>
}                
<Form.Group>
<label className="form -control-label no-padding">descrizione</label>
<Form.Control as="textarea" rows={5} name="descrizione" className="form-control" value={formik.values.descrizione}
onChange={formik.handleChange}
onBlur={formik.handleBlur}
isInvalid ={!!formik.touched.descrizione && !!formik.errors.descrizione}
isValid ={!!formik.touched.descrizione && !formik.errors.descrizione}
></Form.Control>
{
     formik.errors.descrizione  && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.descrizione}
    </Form.Control.Feedback>
)}

</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary" >Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
        <div className="copyright text-center my-auto">
                                            <span>Copyright &copy; elenco imprese 2022</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}

